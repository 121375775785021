
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ThreePreview from "./three-preview.vue";
@Component({
  components: {
    ThreePreview,
  },
})
export default class FileItem extends Vue {
  @Prop({ default: () => {} }) item!: any;
  @Prop({ default: "100" }) size!: string;
  @Prop({ default: "image" }) type!: string;

  fileIcon = require("@/assets/images/file.png");
  imagePreviewShow = false;

  model_url = "";
  image = "";

  getUrl(value: any) {
    if (value.ext) {
      if (["jpg", "png", "jpeg", "gift", ""].indexOf(value.ext) > -1) {
        return value.uri;
      } else {
        return this.fileIcon;
      }
    } else {
      return value.uri;
    }
  }

  handleImagePreview(value: string) {
    this.image = value;
    this.imagePreviewShow = true;
  }
}
