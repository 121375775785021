
import { Component, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import Parts from "@/components/model/parts.vue";
import Effect from "@/components/model/effect.vue";

import {
  apiModelList,
  apiModelDelete,
  apiModelShare,
  apiModelShareList,
  apiModelStatus,
  apiModelFastEdit,
  apiModelCopy,
} from "@/api/model.ts";
import { apiShopLists } from "@/api/shop";
import feedback from "@/utils/feedback";
@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    Parts,
    Effect,
  
  },
})
export default class ModelList extends Vue {
  $refs!: { shopTable: any };
  /** S Data **/

  pager: RequestPaging = new RequestPaging();
  dialogPager: RequestPaging = new RequestPaging();

  searchObj: any = {
    id: "",
    zh_cn_title: "",
    cate_id: "",
  };

  categoryList = [];
  model_id = "";
  dialogShare = false;
  share_shop_ids: any = [];

  shopList: any = [];
  shopTotal = 0;
  shopPrams = {
    id: 0,
    page_no: 1,
    page_size: 50,
    status: -10,
  };

  chooseId: any = [];

  dialogShareList = false;
  shareList: any = [];

  shareStatusList: any = [
    { label: "全部", value: -10 },
    { label: "未分配", value: 0 },
    { label: "已分配", value: 1 },
  ];


  apiModelList = apiModelList;
  // 1=内测,2=禁用,3=启用
  statusList = [
    { label: "内测", value: 1 },
    { label: "下架", value: 2 },
    { label: "上架", value: 3 },
  ];

  /** E Data **/
  getList() {
    this.pager.request({
      callback: apiModelList,
      params: {
        ...this.searchObj,
      },
    });
  }

  // 添加
  handleAdd() {
    this.$router.push({ path: "/model/model_edit" });
  }

  handleEdit(id: string) {
    this.$router.push({
      path: "/model/model_edit",
      query: { id: id, mode: "edit" },
      params: { searchObj: this.searchObj, pager: this.pager as any },
    });
  }

  handleChangeValue(id: number, value: number, key: string) {
    apiModelFastEdit({ id: Number(id), [key]: value }).then((res) => {
      this.$message.success("success");
      this.getList();
    });
  }

  async handleStatus(value: number, id: string) {
    if (value == 1) {
      await feedback.confirm("是否确认改为内测");
    } else if (value == 2) {
      await feedback.confirm("是否确认下架");
    } else if (value == 3) {
      await feedback.confirm("是否确认上架");
    }
    apiModelStatus({ id: id, status: value }).then((res) => {
      this.getList();
    });
  }

  handleShare(row: any) {
    this.model_id = row.id;
    this.shopPrams.id = row.id;
    this.handleShareList();
    this.dialogShare = true;
  }

  handleSelectionShop(value: any) {
    this.chooseId = value.map((item: any) => item.id);
  }

  handleShareList() {
    apiModelShareList({ ...this.shopPrams }).then((res) => {
      this.shareList = res.lists;
    });
  }

  handleToShare(id: any) {
    apiModelShare({
      id: this.model_id,
      shop_ids: id ? id : this.chooseId.join(","),
      share_type: 1,
    }).then((res) => {
      // this.$message.success("分配成功");
      this.handleShareList();
    });
  }
  async cancelShare(id: string) {
    await this.$confirm("是否确认取消分配");
    apiModelShare({
      id: this.model_id,
      shop_ids: id,
      share_type: 2,
    }).then((res) => {
      // this.$message.success("取消分配成功");
      this.handleShareList();
    });
  }

  // 重置搜索
  reset(): void {}

  // 编辑
  handleCopy(id: number) {
    apiModelCopy({ id: id }).then((res) => {
      this.getList();
    });
  }
  // 删除
  handleDelete(id: number) {
    apiModelDelete({ id: id }).then((res) => {
      this.getList();
    });
  }
  /** E Methods **/

  /** S ods **/
  created() {
    this.getList();
  }
}
