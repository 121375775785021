import * as THREE from "three";
import { Camera, MOUSE, Renderer, EventDispatcher } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"; // 导入控制器模块，轨道控制器
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"; // 导入GLTF模块，模型解析器,根据文件格式来定
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

import TWEEN from "@tweenjs/tween.js";

const meshTypeList = [
  {
    type: "",
    describe: "默认材质",
    color: true,
    wireframe: true,
    depthWrite: true,
    opacity: true,
  },
  {
    type: "MeshBasicMaterial",
    describe: "基础网格材质",
    color: true,
    wireframe: true,
    depthWrite: true,
    opacity: true,
  },
  {
    type: "MeshLambertMaterial",
    describe: "Lambert网格材质",
    color: true,
    wireframe: true,
    depthWrite: true,
    opacity: true,
  },
  {
    type: "MeshMatcapMaterial",
    describe: "MeshMatcap材质",
    color: true,
    wireframe: false,
    depthWrite: true,
    opacity: true,
  },
  {
    type: "MeshPhongMaterial",
    describe: "Phong网格材质",
    color: true,
    wireframe: true,
    depthWrite: true,
    opacity: true,
  },
  {
    type: "MeshPhysicalMaterial",
    describe: "物理网格材质",
    color: true,
    wireframe: true,
    depthWrite: true,
    opacity: true,
  },
  {
    type: "MeshStandardMaterial",
    describe: "标准网格材质",
    color: true,
    wireframe: true,
    depthWrite: true,
    opacity: true,
  },
  {
    type: "MeshToonMaterial",
    describe: "卡通着色的材质",
    color: true,
    wireframe: true,
    depthWrite: true,
    opacity: true,
  },
];

class renderModel {
  constructor(selector) {
    this.id = selector;
    this.container = document.querySelector(selector);
    // 相机
    this.camera;
    // 场景
    this.scene = null;
    // 渲染器
    this.renderer;
    // 控制器
    this.controls;
    // 模型
    this.model = {};
    // 几何体模型数组
    this.geometryGroup = new THREE.Group();
    // 几何体模型
    this.geometryModel;
    // 加载进度监听
    this.loadingManager = new THREE.LoadingManager();
    // 文件加载器类型
    this.fileLoaderMap = {
      glb: new GLTFLoader(),
      fbx: new FBXLoader(this.loadingManager),
      gltf: new GLTFLoader(),
      obj: new OBJLoader(this.loadingManager),
      stl: new STLLoader(),
    };
    // 模型动画列表
    this.modelAnimation;
    // 模型动画对象
    this.animationMixer;
    this.animationColock = new THREE.Clock();
    // 动画帧
    this.animationFrame = null;
    // 轴动画帧
    this.rotationAnimationFrame = null;
    // 动画构造器
    this.animateClipAction = null;
    // 动画循环方式枚举
    this.loopMap = {
      LoopOnce: THREE.LoopOnce,
      LoopRepeat: THREE.LoopRepeat,
      LoopPingPong: THREE.LoopPingPong,
    };
    // 模型骨架
    this.skeletonHelper;
    // 网格辅助线
    this.gridHelper;
    // 坐标轴辅助线
    this.axesHelper;
    // 环境光
    this.ambientLight;
    // 平行光
    this.directionalLight;
    // 平行光辅助线
    this.directionalLightHelper;
    // 点光源
    this.pointLight;
    // 点光源辅助线
    this.pointLightHelper;
    // 聚光灯
    this.spotLight;
    // 聚光灯辅助线
    this.spotLightHelper;
    // 模型平面
    this.planeGeometry;
    // 模型材质列表
    this.modelMaterialList = [];
    // 模型材质原始数据缓存
    this.originalMaterials = new Map();
    // 效果合成器
    this.effectComposer;
    this.outlinePass;
    // 动画渲染器
    this.renderAnimation = null;
    // 碰撞检测
    this.raycaster = new THREE.Raycaster();
    // 鼠标位置
    this.mouse = new THREE.Vector2();
    // 辉光效果合成器
    this.glowComposer;
    this.glowRenderPass;
    // 辉光渲染器
    this.unrealBloomPass;
    // 辉光着色器
    this.shaderPass;
    // 需要辉光的材质
    this.glowMaterialList;
    this.materials = {};
    // 拖拽对象控制器
    this.transformControls;
    // 是否开启辉光
    this.glowUnrealBloomPass = false;
    // 窗口变化监听事件
    this.onWindowResizesListener;
    // 鼠标点击事件
    this.onMouseClickListener;
    // 模型上传进度条回调函数
    this.modelProgressCallback = (e) => e;
    // 当前拖拽的几何模型
    this.dragGeometryModel = {};
    // 当前模型加载状态
    this.loadingStatus = true;
    // 3d文字渲染器
    this.css3DRenderer = null;
    // 3d文字控制器
    this.css3dControls = null;
    // 当前拖拽标签信息
    this.dragTag = {};
    // 当前标签列表
    this.dragTagList = [];
    this.container = document.querySelector(selector);

    this.fileLoaderMap = {
      glb: new GLTFLoader(),
      fbx: new FBXLoader(),
      gltf: new GLTFLoader(),
      obj: new OBJLoader(),
      stl: new STLLoader(),
    };
  }

  init({ fileParams, modelParams, materialList, targetSize }) {
    return new Promise(async (reslove, reject) => {
      this.modelParams = modelParams;
      this.materialList = materialList;
      // 初始化渲染器
      this.initRender();
      // 初始化相机
      this.initCamera();
      // 初始化场景
      this.initScene();
      // 初始化控制器，控制摄像头,控制器一定要在渲染器后
      this.initControls();
      // 创建辅助线
      // this.createHelper()
      // 创建灯光
      this.createLight();
      this.addEvenListMouseLisatener();
      // 添加物体模型 TODO：初始化时需要默认一个
      const load = await this.setModel({
        fileParams: fileParams,
        targetSize: targetSize,
        fileType: "glb",
        decomposeName: "transformers_3",
      });
      // 创建效果合成器
      // this.createEffectComposer()
      // 场景渲染
      this.sceneAnimation();
      reslove(load);
    });
  }

  toDataURL(format) {
    return this.renderer.domElement.toDataURL(format || "image/png");
  }

  // 监听事件
  addEvenListMouseLisatener() {
    // 监听场景大小改变，跳转渲染尺寸
    this.onWindowResizesListener = this.onWindowResizes.bind(this);
    window.addEventListener("resize", this.onWindowResizesListener);
  }

  // 监听窗口变化
  onWindowResizes() {
    if (!this.container) return false;
    const { clientHeight, clientWidth } = this.container;
    // 调整屏幕大小
    this.camera.aspect = clientWidth / clientHeight; // 摄像机宽高比例
    this.camera.updateProjectionMatrix(); // 相机更新矩阵，将3d内容投射到2d面上转换
    this.renderer.setSize(clientWidth, clientHeight);
  }

  createBg() {
    this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();
    const rgbeLoader = new RGBELoader();
    rgbeLoader
      .loadAsync(
        // 'https://fq-design.oss-cn-shanghai.aliyuncs.com/system/20240719/ffcdhjeh/346d784b5d6d60ca1fce1578227e1e97.hdr',
        "https://fq-design.oss-cn-shanghai.aliyuncs.com/system/20240719/bbchfccg/15fa6a03d4a15069336bc507321b022d.hdr"
        // 'https://fq-design.oss-cn-shanghai.aliyuncs.com/system/20230413/fed977b3d9af42a497e9120f5b102047.hdr'
      )
      .then((texture) => {
        const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
        this.pmremGenerator.dispose();
        // this.scene.background = new THREE.Color(0xffffff);
        // this.scene.background = envMap
        this.scene.environment = envMap;
      });
    // new THREE.TextureLoader().load(
    //   'https://fq-design.oss-cn-shanghai.aliyuncs.com/system/20240716/jchffeic/ac23c1d341da2fa85b42fa09d3257242.png',
    //   (texture) => {
    //     const envMap = this.pmremGenerator.fromEquirectangular(texture).texture
    //     this.pmremGenerator.dispose()
    //     this.scene.background = new THREE.Color(0xf1f2ed)
    //     // this.scene.background = envMap
    //     this.scene.environment = envMap
    //   }
    // )
  }

  // 创建场景
  async initScene() {
    this.scene = new THREE.Scene();
    // const texture = new THREE.TextureLoader().load(
    //   'https://fq-design.oss-cn-shanghai.aliyuncs.com/system/20240716/jchffeic/ac23c1d341da2fa85b42fa09d3257242.png'
    // )
    // texture.mapping = THREE.EquirectangularReflectionMapping
    // this.scene.background = texture
    // this.scene.environment = texture
    // this.scene.backgroundIntensity = 1
    // this.scene.backgroundBlurriness = 1
    // this.scene.background = new THREE.Color(0xffffff)
    // texture.dispose()
    // this.createBg();
  }

  // 创建相机
  initCamera() {
    this.camera = new THREE.PerspectiveCamera(5, 1, 1, 1000);
    this.camera.position.set(0, 0, 30);

    // 设置相机坐标系
    this.camera.updateProjectionMatrix();
  }

  resetCamera() {
    this.camera.position.set(0, 0, 10); // 设置相机位置
    this.camera.lookAt(...this.scene.position);
  }

  /**
   * 移动相机转场动画
   * @param {*} position
   */
  moveCamera(position) {
    const coords = {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
    // 使用Tween.js创建一个旋转动画
    new TWEEN.Tween(coords) // 初始位置
      .to(position) // 目标位置和动画时间
      .easing(TWEEN.Easing.Sinusoidal.InOut) // 缓动函数，这里使用 Quadratic.Out
      .onUpdate(() => {
        // 更新元素位置
        this.camera.position.set(coords.x, coords.y, coords.z);
      })
      .start();
  }

  // 创建光源
  createLight() {
    // 创建环境光
    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    this.ambientLight.visible = true;
    this.scene.add(this.ambientLight);

    // 创建平行光
    const light = new THREE.DirectionalLight(0xffffff, 0.5);
    light.position.set(50, 50, 50);
    light.castShadow = true;
    this.scene.add(light);

    if (this.id == "#three-99") {
      const light1 = new THREE.DirectionalLight(0xffffff, 0.5);
      light1.position.set(-50, -30, -50);
      light1.castShadow = true;
      this.scene.add(light1);
    }

    // this.directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    // this.directionalLight.position.set(10, 10, 10);
    // this.directionalLight.castShadow = true;
    // this.directionalLight.visible = true;
    // this.scene.add(this.directionalLight);
    // 创建平行光辅助线
    // this.directionalLightHelper = new THREE.DirectionalLightHelper(
    //   this.directionalLight,
    //   0.3
    // );
    // this.directionalLightHelper.visible = false;
    // this.scene.add(this.directionalLightHelper);

    // // 创建点光源
    this.pointLight = new THREE.PointLight(0xffffff, 2);
    this.pointLight.visible = false;
    this.scene.add(this.pointLight);
    // // 创建点光源辅助线
    // this.pointLightHelper = new THREE.PointLightHelper(this.pointLight, 0.5)
    // this.pointLightHelper.visible = false
    // this.scene.add(this.pointLightHelper)

    //  创建聚光灯
    // this.spotLight = new THREE.SpotLight('#00BABD', 900);
    // this.spotLight.visible = false
    // this.spotLight.map = new THREE.TextureLoader().load(getAssetsFile('image/model-bg-1.jpg'));
    // this.spotLight.map = new THREE.TextureLoader().load(getAssetsFile('image/model-bg-1.jpg'));
    // this.spotLight.decay = 2;
    // this.spotLight.shadow.mapSize.width = 1920;
    // this.spotLight.shadow.mapSize.height = 1080;
    // this.spotLight.shadow.camera.near = 1;
    // this.spotLight.shadow.camera.far = 10;
    // this.scene.add(this.spotLight);
    // 创建聚光灯辅助线
    // this.spotLightHelper = new THREE.SpotLightHelper(this.spotLight);
    // this.spotLightHelper.visible = false
    // this.scene.add(this.spotLightHelper)

    // 模型平面
    // const geometry = new THREE.PlaneGeometry(4, 4);
    // var groundMaterial = new THREE.MeshStandardMaterial({ color: '#000000' });
    // this.planeGeometry = new THREE.Mesh(geometry, groundMaterial);
    // this.planeGeometry.rotation.x = -Math.PI / 2
    // this.planeGeometry.position.set(0, -1.2, 0)

    // 让地面接收阴影
    // this.planeGeometry.receiveShadow = true;
    // this.planeGeometry.visible = false
    // this.scene.add(this.planeGeometry);
  }

  // 创建渲染器
  initRender() {
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    }); // 设置抗锯齿
    // 设置屏幕像素比
    this.renderer.setPixelRatio(window.devicePixelRatio);
    // 渲染的尺寸大小
    const { clientHeight, clientWidth } = this.container;
    this.renderer.setSize(clientWidth, clientHeight);
    // 色调映射
    // this.renderer.toneMapping = THREE.ReinhardToneMapping;
    // this.renderer.autoClear = true;
    // this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    // 曝光
    // this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // this.renderer.toneMappingExposure = 1.5;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.renderer.setClearColor(0xf8f8f8, 0);
    this.container.appendChild(this.renderer.domElement);
  }

  // 创建控制器
  initControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // this.controls.enablePan = false
    this.controls.enableDamping = true;

    if (this.id == "#three-99") {
      this.controls.enablePan = true;
    } else {
      //是否开启右键拖拽
      this.controls.enableRotate = false;
      this.controls.enableZoom = false;
    }

    this.controls.minPolarAngle = 0; // 限制最小角度为 90 度
    // this.controls.maxPolarAngle = Math.PI / 2; // 限制最大角度也为 90 度

    // this.controls.minAzimuthAngle = 0;
    // this.controls.maxAzimuthAngle = 0;

    this.controls.target.set(0, 0, 0);
    this.controls.update();
  }

  // 加载模型
  setModel({ fileParams, targetSize, fileType, decomposeName }) {
    return new Promise((resolve, reject) => {
      this.loadingStatus = false;
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(
        "https://fqpod.oss-cn-guangzhou.aliyuncs.com/draco/gltf/"
      );
      let loader = new GLTFLoader().setDRACOLoader(dracoLoader);
      let model;
      let length = 0;
      fileParams.forEach((file) => {
        loader.load(
          file.url,
          async (result) => {
            model = result.scene;
            model.decomposeName = decomposeName;
            await this.onChangeModelMeshType(model);
            model.scale.set(1.8, 1.8, 1.8);
            model.rotation.set(0, 0, 0);
            model.position.set(0, 0, 0);
            this.scene.add(model);
            this.loadingStatus = true;
            length += 1;
            if (length == fileParams.length) {
              resolve(true);
            }
          },
          (xhr) => {
            if (this.modelProgressCallback) {
              this.modelProgressCallback(xhr.loaded / xhr.total);
            }
          },
          (err) => {
            // ElMessage.error('文件错误')
            console.log(err);
            resolve(true);
          }
        );
      });
    });
  }

  async switchModel({ name, model_url }) {
    let partToReplace = await this.removeCurrentModel(name); // 移除旧模型
    this.scene.remove(partToReplace);
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://fqpod.oss-cn-guangzhou.aliyuncs.com/draco/gltf/"
    );
    let colorList = {};
    partToReplace.children.forEach((item) => {
      if (item.type != "Mesh") {
        item.children.forEach((child) => {
          if (child.type == "Mesh") {
            colorList[child.name] = child.material.color;
          }
        });
      }
    });
    let loader = new GLTFLoader().setDRACOLoader(dracoLoader);
    loader.load(model_url, async (gltf) => {
      let newPart = gltf.scene;
      await this.onChangeModelMeshType(newPart);
      const position = partToReplace.position.clone();
      const rotation = partToReplace.rotation.clone();
      const scale = partToReplace.scale.clone();
      this.scene.remove(partToReplace);

      newPart.position.copy(position);
      newPart.rotation.copy(rotation);
      newPart.scale.copy(scale);
      newPart.children.forEach((item) => {
        if (item.type != "Mesh") {
          item.children.forEach((child) => {
            if (child.type == "Mesh") {
              child.material.color = colorList[child.name];
            }
          });
        }
      });
      this.scene.add(newPart);

      this.sceneAnimation();
    });
  }

  removeCurrentModel(name) {
    return new Promise((resolve, reject) => {
      this.scene.traverse((v) => {
        if (v.name == name) {
          resolve(v.parent.parent);
        }
      });
    });
  }
  onChangeModelMeshType(model) {
    return new Promise((resolve, reject) => {
      model.traverse(async (v) => {
        this.model[v.name] = v;
        if (v.isMesh && v.material) {
          let MaterialType = v.material.type;
          let {
            name,
            color,
            map,
            wireframe,
            depthWrite,
            opacity,
            transparent,
            alphaTest,
          } = v.material;
          transparent = true;
          alphaTest = 0.1;
          let modelParams = this.modelParams[v.name];
          if (modelParams) {
            if (modelParams.material_type) {
              MaterialType = modelParams.material_type;
            }
            transparent = modelParams.transparent ? true : false;
            opacity = modelParams.opacity;
            color = modelParams.model_color || color;
            depthWrite = modelParams.depth_write ? true : false;
            alphaTest = modelParams.alpha_test || 0.1;
            if (modelParams.side) {
              side = THREE.DoubleSide;
            }
            const normalMapValue = this.modelParams[v.name].normalMap;
            v.material = new THREE[MaterialType]({
              map,
              transparent,
              color,
              opacity,
              name,
              depthWrite,
              alphaTest,
            });
            if (modelParams.blend == 1) {
              //遮挡
              v.material.blending = THREE.NoBlending; //不进行任何混合，新像素完全替换旧像素
              v.material.blendSrc = THREE.SrcAlphaFactor; //使用源像素的alpha值
              v.material.blendDst = THREE.ZeroFactor; //完全不考虑目标像素的颜色值
            }
            if (this.id == "#three-99") {
              v.material.roughness = 0.5;
              v.material.metalness = 0;
            }
            const texture = this.modelParams[v.name].texture;
            if (texture) {
              this.setMap({
                name: v.name,
                url: texture,
              });
            }
            const normalMap = this.modelParams[v.name].normalMap;
            if (normalMap) {
              this.setNormalMap({
                name: v.name,
                img: normalMap.img,
                repeat: normalMap.repeat,
                scale: normalMap.scale,
              });
            }
            if (v.material.normalMap && normalMapValue) {
              this.setNormalMap({
                name: v.name,
                img: normalMapValue.img,
                repeat: normalMapValue.repeat,
                scale: normalMapValue.scale,
              });
            }
          } else {
            v.material = new THREE[MaterialType]({
              map,
              transparent,
              opacity,
              color,
              name,
              depthWrite,
              alphaTest,
            });
          }
        }
      });
      resolve(true);
    });
  }

  async setModelColor({ name, color }) {
    return new Promise((resolve, reject) => {
      const mesh = this.model[name];
      if (!mesh) {
        return;
      }
      const newMaterial = mesh.material.clone();
      newMaterial.color = new THREE.Color(color);
      mesh.material = newMaterial;
      resolve(true);
    });
  }

  // 设置贴图
  async setMap({ name, url, remove_color }) {
    return new Promise((resolve, reject) => {
      const mesh = this.model[name];
      if (!url) {
        resolve(true);
        return;
      }
      if (!mesh) {
        resolve(true);
        return;
      }
      new THREE.TextureLoader().load(url, (texture) => {
        texture.needsUpdate = true;
        // texture.encoding = THREE.sRGBEncoding;
        const newMaterial = mesh.material.clone();
        newMaterial.map = texture;
        newMaterial.map.wrapS = THREE.MirroredRepeatWrapping;
        newMaterial.map.wrapT = THREE.MirroredRepeatWrapping;
        // newMaterial.map.flipY = false;
        newMaterial.map.colorSpace = THREE.SRGBColorSpace;
        newMaterial.map.minFilter = THREE.LinearFilter;
        newMaterial.map.magFilter = THREE.LinearFilter;
        mesh.material = newMaterial;
        texture.dispose();
        if (remove_color && remove_color != "#fff") {
          newMaterial.color = new THREE.Color(0xffffff);
        }
        setTimeout(async () => {
          let img = this.renderer.domElement.toDataURL("image/png");
          resolve(img);
        }, 100);
      });
    });
  }

  // 设置法线贴图
  setNormalMap({ name, img, repeat, scale }) {
    return new Promise((resolve, reject) => {
      const mesh = this.model[name];
      new THREE.TextureLoader().load(img, (texture) => {
        texture.needsUpdate = true;
        texture.repeat.set(Number(repeat), Number(repeat));
        const newMaterial = mesh.material.clone();
        newMaterial.normalMap = texture;
        newMaterial.normalMap.wrapS = THREE.RepeatWrapping;
        newMaterial.normalMap.wrapT = THREE.RepeatWrapping;
        newMaterial.normalScale.set(Number(scale), Number(scale));
        mesh.material = newMaterial;
        texture.dispose();
        resolve(true);
      });
    });
  }

  setModelPositionSize(model, targetSize = 5) {
    // 设置模型位置
    model.updateMatrixWorld();
    const box = new THREE.Box3().setFromObject(model);
    const size = box.getSize(new THREE.Vector3());
    const center = box.getCenter(new THREE.Vector3());
    // 计算缩放比例
    const maxSize = Math.max(size.x, size.y, size.z);
    // const targetSize = 4 // 目标大小
    const scale = targetSize / (maxSize > 1 ? maxSize : 0.5);
    model.scale.set(scale, scale, scale);
    // 设置模型位置
    model.position.sub(center.multiplyScalar(scale));
    // 设置控制器最小缩放值
    // controls.maxDistance = size.length() * 10;
    // 设置相机位置
    this.camera.position.set(0, 2, 6);
    // 设置相机坐标系
    this.camera.updateProjectionMatrix();
  }

  onWindowResize(size = 1200) {
    return new Promise((resolve, reject) => {
      this.renderer.setPixelRatio(1);
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        size / window.devicePixelRatio,
        size / window.devicePixelRatio
      );
      setTimeout(() => {
        let img = this.renderer.domElement.toDataURL("image/png");
        const { clientHeight, clientWidth } = this.container;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(clientHeight, clientWidth);
        resolve(img);
      }, 100);
    });
  }

  // 更新场景
  sceneAnimation() {
    this.renderAnimation = requestAnimationFrame(() => this.sceneAnimation());
    // 等模型加载和相关数据处理完成在执行
    if (this.loadingStatus) {
      // 辉光效果开关开启时执行
      if (this.glowUnrealBloomPass) {
        // 将不需要处理辉光的材质进行存储备份
        // this.setMeshFlow()
      } else {
        // this.effectComposer.render()
        this.controls.update();
        this.renderer.render(this.scene, this.camera);
      }
      TWEEN.update();
      // 3d标签渲染器
      // if (this.dragTagList.length) {
      // 	this.css3DRenderer.render(this.scene, this.camera)
      // 	this.css3dControls.update()
      // }
    }
  }

  // 模型加载进度条回调函数
  onProgress(callback) {
    if (typeof callback === "function") {
      this.modelProgressCallback = callback;
    }
  }
}

export default renderModel;
