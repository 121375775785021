
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsMaterial from "./material.vue";
import FileItem from "./file-item.vue";
import Draggable from "vuedraggable";

@Component({
  components: {
    LsDialog,
    LsMaterial,
    Draggable,
    FileItem,
  },
})
export default class MediaSelect extends Vue {
  visible = { val: false };
  select!: any[];
  onTrigger() {
    this.open();
  }
  close() {
    this.visible.val = false;
    this.$emit("close");
  }

  open() {
    this.visible.val = true;
    this.$emit("open");
  }

  selectChange(val: any[]) {
    this.select = val;
  }

  handleConfirm() {
    this.close();
    this.$emit("confirm", this.select[0]["uri"]);
  }
}
