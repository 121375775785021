
import { Component, Prop, Vue } from "vue-property-decorator";
import Editor from "@/components/editor.vue";
import MaterialSelect from '@/components/material-select/index.vue'

@Component({
  components: {
    Editor, MaterialSelect
  },
})
export default class AddDetails extends Vue {
  @Prop() value: any;
  @Prop({ default: "zh_cn_content" }) filed!: "zh_cn_content";
}
