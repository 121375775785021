import request from "@/plugins/axios";

// 登录
export const apiLogin = (data: any) => request.post("/login/account", data);

// 退出登录
export const apiLogout = () => request.get("/login/logout");

// 配置
export const apiconfig = () => request.get("/settings.platform/getConfig");

// 退出登录
export const apiDownLoad = () => request.get("", { params: { d: 1 } });

// 页面跳转列表
export const apiLinkList = (params: any) =>
  request.get("/theme.DecorateThemePage/getshoppage", { params });

// 页面跳转列表
export const apiPcLinkList = (params: any) =>
  request.get("/theme.PcDecorateThemePage/getPcpage", { params });

// 权限列表
export const apiAuth = () => request.get("/config/getAuth");

/** S 素材管理 **/

// 添加文件分类
export const apiFileAddCate = (params: any) =>
  request.post("/file/addCate", params);

// 编辑文件分类
export const apiFileEditCate = (params: any) =>
  request.post("/file/editCate", params);

// 删除文件分类
export const apiFileDelCate = (params: { id: number }) =>
  request.post("/file/delCate", params);

// 文件分类列表
export const apiFileListCate = (params: any) =>
  request.get("/file/listCate", { params });

// 文件列表
export const apiFileList = (params: any) =>
  request.get("/file/lists", { params });

// 文件删除
export const apiFileDel = (params: { ids: any[] }) =>
  request.post("/file/delete", params);

// 文件移动
export const apiFileMove = (params: { ids: any[]; cid: number }) =>
  request.post("/file/move", params);

// 文件重命名
export const apiFileRename = (params: { id: number; name: string }) =>
  request.post("file/rename", params);

export const apiFileUpload = (params: { id: number; name: string }) =>
  request.post("upload/image", params);

/** E 素材管理 **/

export const apiAliToken = () => request.get("upload/getAliyunStsToken");
