
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Draggable from "vuedraggable";
import MaterialSelect from "@/components/material-select/index.vue";
import {
  apiColorList,
  apiModelMaterialList,
  apiModelDesignPartsLists,
  apiModelEffectLists,
} from "@/api/model";

@Component({
  components: {
    Draggable,
    MaterialSelect,
  },
})
export default class Attrs extends Vue {
  @Prop() value: any;
  @Prop({ default: 0 }) model_id!: number;
  title = "";
  // list: any = [];
  attrItemName = "";
  galleryShow = false;
  attrIndex = 0;
  attrItemIndex = 0;
  imageIndex = 0;

  typeList = [
    { label: "自定义颜色", value: "1" },
    { label: "指定颜色", value: "2" },
    { label: "材质", value: "3" },
    { label: "效果图", value: "4" },
    { label: "贴图", value: "5" },
    { label: "换模型", value: "6" },
  ];

  colorList: any = [];
  materialList: any = [];
  designPartsList: any = [];
  effectList: any = [];
  list: any = [];

  @Watch("value") valueChange(value: any) {
    this.list = typeof value === "string" ? JSON.parse(value) : value;
  }

  addAttrItem() {
    this.list.push({
      id: "",
      name: "",
      source: 1,
      attr_list: [
        {
          id: "",
          value: "",
          price: "",
          weight: "",
          zh_cn_name: "默认",
          en_name: "default",
          model_url: [],
        },
      ],
    });
  }

  changType(value: any, row: any) {
    row.type_name = this.typeList.find(
      (item: any) => item.value == value
    )?.label;
    if (value == 1) {
      this.$set(row, "attr_list", [
        {
          id: "",
          value: "",
          price: "",
          weight: "",
          zh_cn_name: "默认",
          en_name: "default",
        },
      ]);
    }
  }

  //
  removeAttrItem(index: number) {
    this.list.splice(index, 1);
  }

  selectColor(event: any, row: any) {
    const value = this.colorList.find((color: any) => color.id == event);
    row.color = value.color;
    row.zh_cn_name = value.zh_cn_name;
    row.en_name = value.en_name;
  }

  selectMaterial(event: any, row: any) {
    const value = this.materialList.find(
      (material: any) => material.id == event
    );
    row.image = value.image;
    row.zh_cn_name = value.zh_cn_name;
    row.en_name = value.en_name;
  }

  checkValue(index: number, sindex: number) {
    const value = this.list[index].attr_list[sindex].value;
    this.list[index].attr_list.forEach((item: any, idx: number) => {
      if (item.value && sindex != idx && item.value == value) {
        this.list[index].attr_list[sindex].value = "";
        this.$message({
          message: "已存在相同规格值",
          type: "warning",
        });
      }
    });
  }

  addAttrValue(index: number) {
    this.list[index].attr_list.push({
      id: "",
      value: "",
      model_url: [],
    });
  }

  removeAttrValue(index: number, sindex: number) {
    this.list[index].attr_list.splice(sindex, 1);
  }

  remoteColor(query: string) {
    if (query !== "") {
      this.getColorList(query);
    } else {
      this.getColorList();
    }
  }

  getColorList(name = "") {
    apiColorList({ page_no: 1, page_size: 99, zh_cn_name: name }).then(
      (res: any) => {
        this.colorList = res.lists;
        this.colorList.forEach((item: any) => {
          item.id = String(item.id);
        });
      }
    );
  }

  remoteMaterial(query: string) {
    if (query !== "") {
      this.getMaterial(query);
    } else {
      this.getMaterial();
    }
  }

  getMaterial(name = "") {
    apiModelMaterialList({ page_no: 1, page_size: 99, zh_cn_name: name }).then(
      (res: any) => {
        this.materialList = res.lists;
        this.materialList.forEach((item: any) => {
          item.id = String(item.id);
        });
      }
    );
  }

  getDesignParts() {
    apiModelDesignPartsLists({ model_id: this.model_id }).then((res: any) => {
      this.designPartsList = res.lists;
    });
  }

  getEffects() {
    apiModelEffectLists({ model_id: this.model_id }).then((res: any) => {
      this.effectList = res.lists;
    });
  }
  handleChange() {
    this.$emit("input", this.list);
  }

  mounted() {
    this.getDesignParts();
    this.getEffects();
    this.getColorList();
    this.getMaterial();
  }
}
