
import {
  apiTemplateAmazonSiteLists,
  apiTemplateAmazonSiteDel,
  apiSrontabOperate
} from '@/api/setting'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
  components: {
    LsDialog,
    LsPagination
  }
})
export default class Task extends Vue {
    // 分页
    pager: RequestPaging = new RequestPaging();
    apiTemplateAmazonSiteLists = apiTemplateAmazonSiteLists

    // 获取列表
    getList () {
      this.pager.request({
        callback: apiTemplateAmazonSiteLists,
        params: {}
      })
    }

    async onStop (row: any): Promise<void> {
      await apiSrontabOperate({
        id: row.id,
        operate: row.status == 1 ? 'stop' : 'start'
      })
      this.getList()
    }

    // 删除这个定时任务
    async onDel (id: any): Promise<void> {
      await apiTemplateAmazonSiteDel({ id: id })
      this.getList()
    }

    // 新增
    goTaskAdd () {
      this.$router.push({
        path: '/setting/site/site_edit',
        query: {
          mode: PageMode.ADD
        }
      })
    }

    // 编辑
    goTaskEdit (id: number) {
      this.$router.push({
        path: '/setting/site/site_edit',
        query: {
          id: id as any,
          mode: PageMode.EDIT
        }
      })
    }

    // 模板管理
    goTemplate (id: number) {
      this.$router.push({
        path: '/setting/site/site_template',
        query: {
          id: id as any
        }
      })
    }

    created () {
      this.getList()
    }
}
