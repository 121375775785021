<template>
  <div
    v-if="type == 2"
    class="preview-box"
    v-loading="loading"
    element-loading-background="rgba(255, 255, 255, 1)"
  >
    <div
      v-for="(img, index) in images"
      :key="index"
      :style="{ 'z-index': index >= imgLayer ? index + 1 : index }"
      style="position: absolute; left: 0; top: 0; width: 100%; height: 100%"
    >
      <img :src="img" alt="" />
    </div>

    <div
      :id="`three-${id}`"
      :style="{ 'z-index': imgLayer }"
      style="position: absolute; left: 0; top: 0; width: 1600px; height: 1600px"
    ></div>
  </div>
  <div v-else-if="type == 3" class="preview-box">
    <div
      :id="`three-${id}`"
      :style="{ width: width + 'px', height: height + 'px' }"
    ></div>
  </div>
</template>
<script>
import renderModel from "@/utils/renderModel.js";

export default {
  props: {
    value: {
      type: [Object],
    },
    images: {
      type: Array,
    },
    imgLayer: {
      type: Number,
    },
    modelParams: {
      type: Object,
      default: () => {},
    },
    materialList: {
      type: Object,
      default: () => {},
    },
    modelUrl: {
      type: Array,
    },
    id: {
      type: [String, Number],
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 600,
    },
    type: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      modelApi: {},
      loading: true,
    };
  },
  watch: {
    images: {
      handler(val) {},
    },
  },
  methods: {
    moveCamera(params) {
      this.modelApi.moveCamera(params);
    },
    async setMapTexture(params) {
      return new Promise(async (resolve, reject) => {
        let img = await this.modelApi.setMap(params);
        resolve(img);
      });
    },
    async switchModel(params) {
      let parts = params.parts;
      for (let part of parts) {
        await this.modelApi.switchModel({
          name: part,
          model_url: params.model_url,
        });
      }
    },
    async setModelColor(params) {
      await this.modelApi.setModelColor(params);
    },

    async setNormalMap(params) {
      await this.modelApi.setNormalMap(params);
    },

    async importScene(json) {
      await this.modelApi.importScene(json);
    },

    async init() {
      return new Promise(async (resolve, reject) => {
        this.modelApi = new renderModel("#three-" + this.id);
        const params = {
          modelParams: this.modelParams,
          materialList: this.materialList,
          targetSize: 2,
        };
        params.fileParams = Array.isArray(this.modelUrl)
          ? this.modelUrl
          : [this.modelUrl];
        if (!params.fileParams[0]["url"]) {
          this.loading = false;
          resolve(true);
          return;
        }
        setTimeout(async () => {
          const load = await this.modelApi.init(params);
          this.loading = false;
          resolve(true);
        }, 50);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 600px;
  height: 600px;
  img {
    width: inherit;
    height: inherit;
  }
}
.effect-three {
  position: absolute;
  left: 100%;
  width: 600px;
  height: 600px;
}
</style>
