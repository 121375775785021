<template>
    <div class="substance_edit-help">
        <div class="ls-card">
            <el-page-header content="产品列表" @back="$router.go(-1)" />
        </div>
        <div class="ls-card m-t-16 form-container">
            <el-form size="small" inline label-width="100px">
                <el-form-item label="产品名称">
                    <el-input v-model="queryObj.product_name" />
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" @click="getList"
                        >搜索</el-button
                    >
                    <el-button size="small" @click="reset">重置</el-button>
                    <el-button type="success" size="small" @click="update"
                        >更新</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
        <div class="ls-card m-t-16">
            <el-table :data="pager.lists" size="small">
                <el-table-column label="产品名称" prop="product_name" />
                <el-table-column label="详情" prop="description" />
                <el-table-column label="创建时间" prop="create_time" />
            </el-table>
        </div>

        <el-dialog :visible.sync="visible" title="同步产品">
            <el-form size="small" ref="formRef" :model="handleData">
                <el-form-item
                    label-width="100px"
                    v-for="(item, index) in form"
                    :label="item.label"
                    :key="index"
                    :prop="item.value"
                    :rules="{
                        required: true,
                        message: '请输入' + item.label,
                        trigger: 'blur',
                    }"
                >
                    <el-input
                        v-if="item.value !== 'image'"
                        v-model="handleData[item.value]"
                    />
                    <material-select
                        v-else
                        :limit="1"
                        v-model="handleData[item.value]"
                    />
                </el-form-item>
                <el-form-item>
                    <div class="flex row-right col-center">
                        <el-button size="small" @click="cancel">取消</el-button>
                        <el-button size="small" type="primary" @click="confirm"
                            >确认</el-button
                        >
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import { RequestPaging } from '@/utils/util'
import {
  apiExpressSystemExpressProductLists,
  apiExpressSystemExpressProductAccountFrom,
  apiExpressSystemExpressProductSync
} from '@/api/express.ts'
import MaterialSelect from '@/components/material-select/index.vue'
export default {
  components: {
    MaterialSelect
  },
  data () {
    return {
      apiExpressSystemExpressProductLists,
      pager: new RequestPaging(),
      queryObj: {
        system_express_id: '',
        product_name: ''
      },
      handleData: {},
      form: [],
      visible: false
    }
  },
  methods: {
    confirm () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          apiExpressSystemExpressProductSync({
            system_express_id: this.queryObj.system_express_id,
            data: {
              ...this.handleData
            }
          }).then((res) => {
            this.$message.success('同步成功')
            this.visible = false
            this.getList()
          })
        }
      })
    },
    cancel () {
      this.visible = false
      Object.keys(this.handleData).forEach((item) => {
        this.handleData[item] = ''
      })
    },
    update () {
      apiExpressSystemExpressProductAccountFrom({
        system_express_id: this.queryObj.system_express_id
      }).then((res) => {
        const data = []
        const obj = {}
        Object.keys(res).forEach((item) => {
          data.push({
            label: res[item],
            value: item
          })
        })
        Object.keys(res).forEach((item) => {
          obj[item] = ''
        })
        this.handleData = obj
        this.form = data
        this.visible = true
      })
    },
    reset () {
      this.queryObj.product_name = ''
      this.getList()
    },
    getList () {
      this.pager.request({
        callback: apiExpressSystemExpressProductLists,
        params: {
          ...this.queryObj
        }
      })
    }
  },
  created () {
    this.queryObj.system_express_id = this.$route.query.id
    this.getList()
  }
}
</script>
