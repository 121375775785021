import request from '@/plugins/axios'

// 物流商列表
export const apiExpressSystemExpressEngineList = (params: any) =>
  request.get('/express.systemExpress/engineList', { params })
// 物流列表
export const apiExpressSystemExpressList = (params: any) =>
  request.get('/express.systemExpress/lists', { params })
// 新增物流
export const apiExpressSystemExpressAdd = (data: any) =>
  request.post('/express.systemExpress/add', data)
// 删除物流
export const apiExpressSystemExpressDel = (data: any) =>
  request.post('/express.systemExpress/del', data)
// 物流详情
export const apiExpressSystemExpressDetail = (data: any) =>
  request.post('/express.systemExpress/detail', data)
// 物流编辑
export const apiExpressSystemExpressEdit = (data: any) =>
  request.post('/express.systemExpress/edit', data)
// 物流下的产品列表
export const apiExpressSystemExpressProductLists = (params: any) =>
  request.get('/express.systemExpressProduct/lists', { params })
// 物流下的产品列表获取授权表单
export const apiExpressSystemExpressProductAccountFrom = (params: any) =>
  request.get('/express.systemExpressProduct/accountFrom', { params })
// 物流下的产品列表同步表单
export const apiExpressSystemExpressProductSync = (data: any) =>
  request.post('/express.systemExpressProduct/sync', data)
