<template>
    <div class="shop-index">
        <div class="ls-card header">
            <el-form size="small" inline label-width="100px">
                <el-form-item label="名称">
                    <el-input v-model="queryObj.name" />
                </el-form-item>
                <el-form-item label="物流商类型">
                    <el-select v-model="queryObj.engine_class">
                        <el-option
                            v-for="(item, index) in engineList"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getList">搜索</el-button>
                    <el-button @click="reset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="ls-card m-t-32">
            <div>
                <el-button size="small" type="primary" @click="add"
                    >新增</el-button
                >
            </div>
            <el-table class="m-t-24" size="small" :data="pager.lists">
                <el-table-column label="物流名称" prop="name"></el-table-column>
                <el-table-column
                    label="物流类别"
                    prop="engine_class"
                ></el-table-column>
                <el-table-column
                    label="联系方式"
                    prop="contact"
                ></el-table-column>
                <el-table-column label="联系链接" prop="contact_link">
                    <template slot-scope="{ row }">
                        <el-link
                            type="primary"
                            v-if="row.contact_link"
                            :href="row.contact_link"
                            target="_blank"
                            >联系链接</el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column label="帮助链接" prop="help_link">
                    <template slot-scope="{ row }">
                        <el-link
                            type="primary"
                            v-if="row.help_link"
                            :href="row.help_link"
                            target="_blank"
                            >帮助链接</el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    label="城市提示"
                    prop="city_remark"
                ></el-table-column>
                <el-table-column label="备注" prop="remark"></el-table-column>
                <el-table-column label="状态" prop="status">
                    <template slot-scope="{ row }">
                        <div>
                            {{ row.status === 1 ? "启用" : "禁用" }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="排序" prop="sort"></el-table-column>
                <el-table-column
                    label="创建时间"
                    prop="create_time"
                ></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="{ row }">
                        <el-button
                            type="text"
                            size="small"
                            @click="edit(row.id)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            @click="
                                $router.push({
                                    path: `/express/logistics_goods?id=${row.id}`,
                                })
                            "
                            >产品列表</el-button
                        >
                        <el-popconfirm
                            confirm-button-text="确认"
                            cancel-button-text="取消"
                            icon="el-icon-info"
                            icon-color="red"
                            title="确认删除吗？"
                            class="m-l-12"
                            @confirm="del(row.id)"
                        >
                            <el-button
                                style="color: red"
                                type="text"
                                size="small"
                                slot="reference"
                                >删除</el-button
                            >
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <div class="flex row-right m-t-24">
                <ls-pagination
                    v-model="pager"
                    @change="getList"
                ></ls-pagination>
            </div>
        </div>

        <el-dialog title="新增物流" :visible.sync="visible">
            <ls-form
                :dataForm="handleData"
                :formData="formData"
                @confirm="dialogCofirm"
            ></ls-form>
        </el-dialog>
    </div>
</template>
<script>
import {
  apiExpressSystemExpressEngineList,
  apiExpressSystemExpressList,
  apiExpressSystemExpressAdd,
  apiExpressSystemExpressDel,
  apiExpressSystemExpressDetail,
  apiExpressSystemExpressEdit
} from '@/api/express.ts'
import { RequestPaging } from '@/utils/util'
import lsPagination from '@/components/ls-pagination.vue'
import lsForm from '@/components/ls-form.vue'
export default {
  components: { lsPagination, lsForm },
  data () {
    return {
      apiExpressSystemExpressAdd,
      apiExpressSystemExpressEdit,
      queryObj: {
        name: '',
        engine_class: ''
      },
      formData: [
        {
          type: 'input',
          label: '物流名称',
          key: 'name',
          rules: [
            {
              required: true,
              message: '请输入名称',
              trigger: 'blur'
            }
          ]
        },
        {
          type: 'select',
          label: '物流类别',
          key: 'engine_class',
          list: [],
          rules: [
            {
              required: true,
              message: '请选择物流类别',
              trigger: 'blur'
            }
          ]
        },
        {
          type: 'input',
          label: '帮助链接',
          key: 'help_link'
        },
        {
          type: 'input',
          label: '联系方式',
          key: 'contact',
          rules: [
            {
              required: true,
              message: '请输入联系方式',
              trigger: 'blur'
            }
          ]
        },
        {
          type: 'input',
          label: '联系链接',
          key: 'contact_link'
        },
        {
          type: 'input',
          label: '城市提示',
          key: 'city_remark'
        },
        {
          type: 'switch',
          label: '状态',
          key: 'status',
          active: 1,
          inactive: 2,
          rules: [
            {
              required: true,
              message: '请选择状态',
              trigger: 'blur'
            }
          ]
        },
        {
          type: 'input',
          label: '排序',
          key: 'sort',
          rules: [
            {
              required: true,
              message: '请输入排序',
              trigger: 'blur'
            }
          ]
        },
        {
          type: 'input',
          label: '备注',
          key: 'remark'
        }
      ],
      engineList: [],
      handleData: {
        id: '',
        name: '',
        engine_class: '',
        help_link: '',
        contact: '',
        contact_link: '',
        city_remark: '',
        status: 1,
        sort: 1,
        remark: ''
      },
      pager: new RequestPaging(),
      apiExpressSystemExpressList,
      visible: false
    }
  },
  methods: {
    edit (id) {
      this.handleData.id = id
      apiExpressSystemExpressDetail({
        id: this.handleData.id
      }).then((res) => {
        this.handleData.id = res.id
        this.handleData.name = res.name
        this.handleData.engine_class = res.engine_class
        this.handleData.help_link = res.help_link
        this.handleData.contact = res.contact
        this.handleData.contact_link = res.contact_link
        this.handleData.city_remark = res.city_remark
        this.handleData.status = res.status
        this.handleData.sort = res.sort
        this.handleData.remark = res.remark
        this.visible = true
      })
    },
    del (id) {
      apiExpressSystemExpressDel({
        id
      }).then((res) => {
        this.$message.success('操作成功')
        this.getList()
      })
    },
    dialogCofirm (e) {
      const api = e.id
        ? apiExpressSystemExpressEdit
        : apiExpressSystemExpressAdd
      api(e).then((res) => {
        this.visible = false
        this.$message.success('操作成功')
        this.resetHandleData()
        this.getList()
      })
    },
    resetHandleData () {
      Object.keys(this.handleData).forEach((key) => {
        if (key != 'status' && key != 'sort') {
          this.handleData[key] = ''
        }
      })
    },
    add () {
      this.resetHandleData()
      this.visible = true
    },
    getList () {
      this.pager.request({
        callback: apiExpressSystemExpressList,
        params: {
          ...this.queryObj
        }
      })
    },
    getEnglineList () {
      apiExpressSystemExpressEngineList().then((res) => {
        this.engineList = this.convertObjectToOptionsArray(res)
        this.formData[1].list = this.engineList
      })
    },
    convertObjectToOptionsArray (obj) {
      return Object.keys(obj).map((key) => {
        return {
          label: obj[key],
          value: key
        }
      })
    },
    reset () {
      this.queryObj.name = ''
      this.queryObj.engine_class = ''
      this.getList()
    }
  },
  created () {
    this.getEnglineList()
    this.getList()
  }
}
</script>
