<template>
  <div class="ls-dialog">
    <div class="ls-dialog__trigger" @click="onTrigger">
      <!-- 触发弹窗 -->
      <slot name="trigger"></slot>
    </div>
    <el-dialog
      coustom-class="ls-dialog__content"
      :visible="visible"
      width="640px"
      title="模型预览"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="close"
      :close-on-click-modal="true"
    >
      <!-- 弹窗内容 -->
      <div id="three" v-if="visible" style="width: 600px; height: 600px"></div>
    </el-dialog>
  </div>
</template>

<script>
import * as THREE from "three";
import { Camera, MOUSE, Renderer, EventDispatcher } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"; // 导入控制器模块，轨道控制器
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"; // 导入GLTF模块，模型解析器,根据文件格式来定
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

import TWEEN from "@tweenjs/tween.js";
export default {
  props: {
    model_url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      scene: null,
      camera: null,
      renderer: null,
      container: null,
      controls: null,
    };
  },
  mounted() {},
  methods: {
    onTrigger() {
      if (this.disabled) return;
      this.open();
    },
    close() {
      this.visible = false;
    },
    open() {
      this.visible = true;
      setTimeout(() => {
        this.container = document.getElementById("three");
        this.init();
      }, 100);
    },
    // 创建场景
    async initScene() {
      this.scene = new THREE.Scene();
    },

    // 创建相机
    initCamera() {
      this.camera = new THREE.PerspectiveCamera(45, 1, 0.1, 500);
      // this.moveCamera({ x: 0, y: 0, z: 10 });
      this.camera.position.set(0, 0, 10);
      // 设置相机坐标系
      this.camera.updateProjectionMatrix();
    },
    initRender() {
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        preserveDrawingBuffer: true,
      }); // 设置抗锯齿
      // 设置屏幕像素比
      this.renderer.setPixelRatio(window.devicePixelRatio);
      // 渲染的尺寸大小
      const { clientHeight, clientWidth } = this.container;
      this.renderer.setSize(clientWidth, clientHeight);

      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

      this.renderer.setClearColor(0xf8f8f8, 0);
      this.container.appendChild(this.renderer.domElement);
    },

    // 创建控制器
    initControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      // this.controls.enablePan = false
      this.controls.enableDamping = true;

      this.controls.enablePan = true;

      this.controls.minPolarAngle = 0; // 限制最小角度为 90 度
      // this.controls.maxPolarAngle = Math.PI / 2; // 限制最大角度也为 90 度

      // this.controls.minAzimuthAngle = 0;
      // this.controls.maxAzimuthAngle = 0;

      this.controls.target.set(0, 0, 0);
      this.controls.update();
    },
    // 更新场景
    sceneAnimation() {
      this.renderAnimation = requestAnimationFrame(() => this.sceneAnimation());
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
      TWEEN.update();
    },

    initLight() {
      // 创建环境光
      let ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
      ambientLight.visible = true;
      this.scene.add(this.ambientLight);
      // 创建平行光
      let light = new THREE.DirectionalLight(0xffffff, 0.5);
      light.position.set(50, 50, 50);
      this.scene.add(light);
    },
    setModel() {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(
        "https://fqpod.oss-cn-guangzhou.aliyuncs.com/draco/gltf/"
      );
      let loader = new GLTFLoader().setDRACOLoader(dracoLoader);
      let model;
      loader.load(
        this.model_url,
        async (result) => {
          model = result.scene;
          model.scale.set(3, 3, 3);
          model.rotation.set(0, 0, 0);
          model.position.set(0, 0, 0);
          this.scene.add(model);
        },
        (xhr) => {
          if (this.modelProgressCallback) {
          }
        },
        (err) => {}
      );
    },

    init() {
      // 初始化渲染器
      this.initRender();
      // 初始化相机
      this.initCamera();
      // 初始化场景
      this.initScene();
      // 初始化控制器，控制摄像头,控制器一定要在渲染器后
      this.initControls();

      this.setModel();

      this.initLight();

      this.sceneAnimation();
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-body {
  white-space: pre-line;
}
.title-icon {
  font-size: 24px;
  color: $--color-warning;
  margin-right: 5px;
}
</style>
