
import { Component, Vue, Watch } from "vue-property-decorator";
import Editor from "@/components/editor.vue";
import { apiModelGetConfig, apiModelSetConfig } from "@/api/model.ts";
@Component({
  components: {
    Editor,
  },
})
export default class ListsDetail extends Vue {
  /** S Data **/
  model_agree_zh_cn: string = "";
  model_agree_en: string = "";
  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit(formName: string) {
    apiModelSetConfig({
      model_agree_zh_cn: this.model_agree_zh_cn,
      model_agree_en: this.model_agree_en,
    }).then((res) => {
     
    });
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    apiModelGetConfig({}).then((res) => {
      this.model_agree_zh_cn = res.model_agree_zh_cn;
      this.model_agree_en = res.model_agree_en;
    });
  }
  /** E Life Cycle **/
}
