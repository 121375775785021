
import { Component, Vue } from 'vue-property-decorator'
import { apiTemplateAmazonSiteAddTemplate, apiTemplateAmazonSiteEditTemplate, apiTemplateAmazonSiteTemplateDetail, apiTemplateAmazonSiteTemplateLists } from '@/api/setting'
import { PageMode } from '@/utils/type'

@Component
export default class TaskEdit extends Vue {
    /** S Data **/
    mode: string = PageMode.ADD // 当前页面: add-添加角色 edit-编辑角色
    // 表
    siteDepartmentList = []
    // 表单数据
    form: any = {
      type: 1, //	类别
      title: '', //	标题
      status: 1, //	状态
      site_id: '', //	站点Id
      template_id: '', //	模板id
      id: '' // id
    }

    page_no = 1
    page_size = 5
    keyWord = ''
    limit = 0
    loadMoreMsg = '——————加载更多——————'
    hasMore = true
    searchId = 0
    // 表单校验
    rules: object = {
      type: [{ required: true, message: '请选择类别', trigger: 'blur' }],
      title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
      status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
      template_id: [{ required: true, message: '请选择模板', trigger: 'blur' }]
    }
    /** E Data **/

    /** S Methods **/
    // 提交表单
    onSubmit (formName: string) {
      const refs = this.$refs[formName] as HTMLFormElement
      refs.validate((valid: boolean): any => {
        if (!valid) return
        // 请求发送
        switch (this.mode) {
          case PageMode.ADD:
            return this.handleNoticeAdd()
          case PageMode.EDIT:
            return this.handleNoticeEdit()
        }
      })
    }

    // 添加定时任务
    async handleNoticeAdd (): Promise<void> {
      await apiTemplateAmazonSiteAddTemplate(this.form)
      setTimeout(() => this.$router.go(-1), 500)
    }

    // 编辑帮助文章
    async handleNoticeEdit (): Promise<void> {
      delete this.form.status_desc
      delete this.form.type_desc
      await apiTemplateAmazonSiteEditTemplate(this.form)
      setTimeout(() => this.$router.go(-1), 500)
    }

    // 初始化表单数据
    async initFormDataForNoticeEdit (): Promise<void> {
      // type: 1, //	类别
      // title: '', //	标题
      // status: 1, //	状态
      // site_id: '', //	站点Id
      // template_id: '', //	模板id
      const res = await apiTemplateAmazonSiteTemplateDetail({
        id: this.form.template_id
      })
      if (res.hasOwnProperty('id')) this.form.id = res.id
      if (res.hasOwnProperty('type')) this.form.type = res.type
      if (res.hasOwnProperty('title')) this.form.title = res.title
      if (res.hasOwnProperty('status')) this.form.status = res.status
      if (res.hasOwnProperty('site_id')) this.form.site_id = res.site_id
      if (res.hasOwnProperty('template_id')) {
        this.form.template_id = res.template_id
        this.searchId = res.template_id
      }
      this.getDepartmentList()
    }

    async getDepartmentList (init = true) {
      const params = {
        page_no: this.page_no,
        page_size: this.page_size,
        title: this.keyWord
      }
      // @ts-ignore
      this.searchId ? params.id = this.searchId : ''
      const res = await apiTemplateAmazonSiteTemplateLists(params)
      this.limit = res.count
      if (init) {
        this.siteDepartmentList = res.lists
      } else {
        this.siteDepartmentList = this.siteDepartmentList.concat(res.lists)
      }

      if (this.siteDepartmentList.length >= this.limit) {
        this.loadMoreMsg = '——————加载完毕——————'
        this.hasMore = false
      } else {
        this.loadMoreMsg = '——————加载更多——————'
        this.hasMore = true
      }
    }

    loadMore () {
      console.log(this.siteDepartmentList.length, '-------------', this.limit)
      if (!this.hasMore) return false
      this.page_no += 1
      this.getDepartmentList(false)
    }

    remoteMethod (keyWord:string) {
      this.page_no = 1
      this.keyWord = keyWord
      this.getDepartmentList(true)
    }

    clearKeyWord () {
      this.keyWord = ''
      this.searchId = 0
      this.getDepartmentList(true)
    }

    /** E Methods **/

    /** S Life Cycle **/
    created () {
      const query: any = this.$route.query
      if (query.mode) this.mode = query.mode
      this.form.site_id = query.site_id * 1

      if (this.mode === PageMode.EDIT) {
        this.form.template_id = query.template_id * 1
        this.initFormDataForNoticeEdit()
      } else {
        this.getDepartmentList()
      }
    }

  /** E Life Cycle **/
}
