import Main from '@/layout/main.vue'
const routes = [
  {
    path: '/express',
    name: 'express',
    meta: { title: '系统物流' },
    redirect: '/express/logistics_management',
    component: Main,
    children: [
      {
        path: '/express/logistics_management',
        name: 'logistics_management',
        meta: {
          title: '物流管理',
          parentPath: '/express',
          icon: '',
          permission: ['view']
        },
        component: () => import('@/views/express/logistics_management.vue')
      },
      {
        path: '/express/logistics_goods',
        name: 'logistics_goods',
        meta: {
          title: '物流产品',
          parentPath: '/express',
          icon: '',
          permission: ['view'],
          hidden: true
        },
        component: () => import('@/views/express/logistics_goods.vue')
      }
    ]
  }
]

export default routes
