import { getCurrentTime, randomString } from "@/utils/util";
const DirName = {
  image: "image",
  effect: "effect",
  factory: "factory",
  design: "design",
};
class Oss {
  constructor(config) {
    let { client, ossdomain } = this.initOssClient(config);
    this.client = client;
    this.ossdomain = ossdomain;
  }

  initOssClient(ossconfig) {
    let OSS = require("ali-oss");
    const ossdomain = ossconfig.domain;
    const client = new OSS({
      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: ossconfig.AccessKeyId,
      accessKeySecret: ossconfig.AccessKeySecret,
      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: ossconfig.SecurityToken,
      refreshSTSToken: async () => {
        // 向您搭建的STS服务获取临时访问凭证。
        const info = await getOssSTSToken();
        return {
          accessKeyId: info.AccessKeyId,
          accessKeySecret: info.AccessKeySecret,
          stsToken: info.SecurityToken,
        };
      },
      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 300000,
      // 填写Bucket名称，例如examplebucket。
      bucket: ossconfig.bucket,
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
      region: `oss-${ossconfig.region}`,
    });
    return { ossdomain, client };
  }

  putb64ToOss({ content, type, name }) {
    return new Promise(async (resolve, reject) => {
      const dir = DirName[type];
      const dataSplitted = content.split(",");
      const format = dataSplitted[0];
      let suffix;
      if (format.indexOf("png") > -1) {
        suffix = "png";
      } else {
        suffix = "jpg";
      }
      const filename = name
        ? `uploads/${dir}/${name}`
        : `uploads/${dir}/${getCurrentTime()}/${randomString(10)}.${suffix}`;
      // base64转blob
      const blob = this.dataURLtoBlob(content);
      // blob转arrayBuffer
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onload = (event) => {
        const file = this.toBuffer(event.target.result);
        this.client.multipartUpload(filename, file, {
          progress: (percentage, checkpoint) => {
            if (percentage == 1) {
              resolve(`${this.ossdomain}/${filename}`);
            }
          },
        });
      };
    });
  }

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  toBuffer(ab) {
    var buf = new Buffer(ab.byteLength);
    var view = new Uint8Array(ab);
    for (var i = 0; i < buf.length; ++i) {
      buf[i] = view[i];
    }
    return buf;
  }

  putBlobToOss(file, type = "factory", filename) {
    return new Promise(async (resolve, reject) => {
      const dir = DirName[type];
      // blob转arrayBuffer
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (event) => {
        // arrayBuffer转Buffer
        const buffer = this.toBuffer(event.target.result);
        //命名规则 以后缀为文件目录  md5为文件名  可自定义
        this.client
          .put(`${dir}/${filename}`, buffer)
          .then((res) => {
            resolve(res.url);
          })
          .catch((err) => {
            console.log(err);
          });
      };
    });
  }
}

export default Oss;
