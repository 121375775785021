import request from "@/plugins/axios";

/** S 模型 **/
export const apiModelList = (params: any) =>
  request.get("/model.modelList/lists", { params });

export const apiModelAdd = (data: any) =>
  request.post("/model.modelList/add", data);

export const apiModelEdit = (data: any) =>
  request.post("/model.modelList/edit", data);

export const apiModelDetail = (params: any) =>
  request.get("/model.modelList/detail", { params });

export const apiModelDelete = (data: any) =>
  request.post("/model.modelList/del", data);

export const apiModelStatus = (data: any) =>
  request.post("/model.modelList/status", data);

export const apiModelShare = (data: any) =>
  request.post("/model.modelList/share", data);

export const apiModelShareList = (params: any) =>
  request.get("/model.modelList/listsSid", { params });

export const apiModelFastEdit = (data: any) =>
  request.post("/model.modelList/fastEdit", data);

export const apiModelCopy = (params: any) =>
  request.get("/model.modelList/copy", { params });
/** E 模型 **/

/** S 分类 **/
export const apiModelCategoryList = (params: any) =>
  request.get("/model.modelCategory/lists", { params });

export const apiModelCategoryAdd = (data: any) =>
  request.post("/model.modelCategory/add", data);

export const apiModelCategoryEdit = (data: any) =>
  request.post("/model.modelCategory/edit", data);

export const apiModelCategoryDetail = (params: any) =>
  request.get("/model.modelCategory/detail", { params });

export const apiModelCategoryDelete = (data: any) =>
  request.post("/model.modelCategory/del", data);
/** E 分类 **/

/** S 材质 **/
export const apiModelMaterialList = (params: any) =>
  request.get("/model.modelMaterial/lists", { params });

export const apiModelMaterialAdd = (data: any) =>
  request.post("/model.modelMaterial/add", data);

export const apiModelMaterialEdit = (data: any) =>
  request.post("/model.modelMaterial/edit", data);

export const apiModelMaterialDetail = (params: any) =>
  request.get("/model.modelMaterial/detail", { params });

export const apiModelMaterialDelete = (data: any) =>
  request.post("/model.modelMaterial/del", data);

/** E 材质 **/

/** S 颜色 **/
export const apiColorList = (params: any) =>
  request.get("/model.modelColor/lists", { params });

export const apiColorAdd = (data: any) =>
  request.post("/model.modelColor/add", data);

export const apiColorEdit = (data: any) =>
  request.post("/model.modelColor/edit", data);

export const apiColorDetail = (params: any) =>
  request.get("/model.modelColor/detail", { params });

export const apiColorDelete = (data: any) =>
  request.post("/model.modelColor/del", data);

/** E 颜色 **/

/** S 定制部位 */
export const apiModelDesignPartsLists = (params: any) =>
  request.get("/model.modelDesignParts/lists", { params });

export const apiModelDesignPartsAdd = (data: any) =>
  request.post("/model.modelDesignParts/add", data);

export const apiModelDesignPartsEdit = (data: any) =>
  request.post("/model.modelDesignParts/edit", data);

export const apiModelDesignPartsDelete = (data: any) =>
  request.post("/model.modelDesignParts/del", data);

export const apiModelDesignFastEdit = (data: any) =>
  request.post("/model.modelDesignParts/fastEdit ", data);
/** E 定制部位 */

/** S 效果图 */
export const apiModelEffectLists = (params: any) =>
  request.get("/model.modelDesignEffect/lists", { params });

export const apiModelEffectAdd = (data: any) =>
  request.post("/model.modelDesignEffect/add", data);

export const apiModelEffectEdit = (data: any) =>
  request.post("/model.modelDesignEffect/edit", data);

export const apiModelEffectDelete = (data: any) =>
  request.post("/model.modelDesignEffect/del", data);

export const apiModelEffectFastEdit = (data: any) =>
  request.post("/model.modelDesignEffect/fastEdit", data);
/** E 效果图 */

export const apiModelGetConfig = (params: any) =>
  request.get("/model.modelList/getConfig", { params });
export const apiModelSetConfig = (data: any) =>
  request.post("/model.modelList/setConfig", data);
