
import { Component, Vue } from 'vue-property-decorator'
import { apiTemplateAmazonSiteAdd, apiTemplateAmazonSiteEdit, apiTemplateAmazonSiteDetail, apiTemplateAmazonSiteDepartment } from '@/api/setting'
import { PageMode } from '@/utils/type'

@Component
export default class TaskEdit extends Vue {
    /** S Data **/
    mode: string = PageMode.ADD // 当前页面: add-添加角色 edit-编辑角色

    // 表
    lists: Array<object> = []

    // 表单数据
    form: any = {
      department_id: '', //	州id
      name: '', //	站点名称
      code: '', //	国家代号
      currency: '', //	货币代号
      currencyName: '', //	货币名称
      currencySymbol: '', //	货币符号
      dataSheet: '', //	字段数据工作表
      templateSheet: '', // 模板工作表
      validValueSheet: '' // 可选项工作表
    }

    // 表单校验
    rules: object = {
      department_id: [{ required: true, message: '请选择大洲', trigger: 'blur' }],
      name: [{ required: true, message: '请输入站点名称', trigger: 'blur' }],
      code: [{ required: true, message: '请输入国家代号', trigger: 'blur' }],
      currency: [{ required: true, message: '请输入货币代号', trigger: 'blur' }],
      currencyName: [{ required: true, message: '请输入货币名称', trigger: 'blur' }],
      currencySymbol: [{ required: true, message: '请输入货币符号', trigger: 'blur' }],
      dataSheet: [{ required: true, message: '请输入字段数据工作表', trigger: 'blur' }],
      templateSheet: [{ required: true, message: '请输入模板工作表', trigger: 'blur' }],
      validValueSheet: [{ required: true, message: '请输入可选项工作表', trigger: 'blur' }]
    }

    siteDepartmentList:any = []

    /** E Data **/

    /** S Methods **/
    // 提交表单
    onSubmit (formName: string) {
      const refs = this.$refs[formName] as HTMLFormElement
      refs.validate((valid: boolean): any => {
        if (!valid) return
        // 请求发送
        switch (this.mode) {
          case PageMode.ADD:
            return this.handleNoticeAdd()
          case PageMode.EDIT:
            return this.handleNoticeEdit()
        }
      })
    }

    // 添加定时任务
    async handleNoticeAdd (): Promise<void> {
      await apiTemplateAmazonSiteAdd(this.form)
      setTimeout(() => this.$router.go(-1), 500)
    }

    // 编辑帮助文章
    async handleNoticeEdit (): Promise<void> {
      delete this.form.status_desc
      delete this.form.type_desc
      await apiTemplateAmazonSiteEdit(this.form)
      setTimeout(() => this.$router.go(-1), 500)
    }

    // 初始化表单数据
    async initFormDataForNoticeEdit (): Promise<void> {
      // department_id: '', //	州id
      // name: 1, //	站点名称
      // code: '', //	国家代号
      // currency: '', //	货币代号
      // currencyName: 1, //	货币名称
      // currencySymbol: '', //	货币符号
      // dataSheet: '', //	字段数据工作表
      // templateSheet: '', // 模板工作表
      // validValueSheet: '' // 可选项工作表
      const res = await apiTemplateAmazonSiteDetail({
        id: this.form.id
      })
      if (res.department.hasOwnProperty('id')) this.form.department_id = res.department.id
      if (res.hasOwnProperty('name')) this.form.name = res.name
      if (res.hasOwnProperty('code')) this.form.code = res.code
      if (res.hasOwnProperty('currency')) this.form.currency = res.currency
      if (res.hasOwnProperty('currencyName')) this.form.currencyName = res.currencyName
      if (res.hasOwnProperty('currencySymbol')) this.form.currencySymbol = res.currencySymbol
      if (res.hasOwnProperty('dataSheet')) this.form.dataSheet = res.dataSheet
      if (res.hasOwnProperty('templateSheet')) this.form.templateSheet = res.templateSheet
      if (res.hasOwnProperty('validValueSheet')) this.form.validValueSheet = res.validValueSheet
    }

    async getDepartmentList () {
      const res = await apiTemplateAmazonSiteDepartment({})
      this.siteDepartmentList = res
    }

    /** E Methods **/

    /** S Life Cycle **/
    created () {
      const query: any = this.$route.query
      if (query.mode) this.mode = query.mode

      if (this.mode === PageMode.EDIT) {
        this.form.id = query.id * 1
        this.initFormDataForNoticeEdit()
      }
      this.getDepartmentList()
    }

  /** E Life Cycle **/
}
